import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  Icon,
  Flex,
  Divider,
  Button,
  useColorMode,
} from "@chakra-ui/react";
import { FiPhoneCall } from "react-icons/fi";
import config from "../../config";
import { useNavigate } from "react-router-dom";

export default function CallHistory() {
  const [calls, setCalls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  const { colorMode } = useColorMode();
  const navigate = useNavigate(); // Used for navigation

  useEffect(() => {
    const fetchCalls = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${config.apiDomain}/calls?page=${page}&items=${itemsPerPage}`,
        );
        if (!response.ok) {
          throw new Error(`Error fetching calls: ${response.statusText}`);
        }
        const data = await response.json();
        setCalls(data.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchCalls();
  }, [page]);

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleCardClick = (callId) => {
    navigate(`/calls/${callId}`);
  };

  if (loading) {
    return <Text>Loading calls...</Text>;
  }

  if (error) {
    return <Text>Error loading calls: {error}</Text>;
  }

  return (
    <Flex direction="column" align="center" p={4}>
      {calls.map((call) => (
        <Box
          key={call.call_id}
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          p={4}
          mb={4}
          bg={colorMode === "light" ? "white" : "gray.700"}
          boxShadow="md"
          width="100%"
          cursor="pointer"
          onClick={() => handleCardClick(call.call_id)} // OnClick to navigate to call details
        >
          <Flex alignItems="center">
            <Icon as={FiPhoneCall} w={6} h={6} mr={2} color="teal.500" />
            <Heading size="md">Call ID: {call.call_id}</Heading>
          </Flex>
          <Divider my={2} />
          <Text>
            <strong>Date:</strong> {new Date(call.date).toLocaleDateString()}
          </Text>
          <Text>
            <strong>To:</strong> {call.to}
          </Text>
          <Text>
            <strong>Status:</strong> {call.call_status}
          </Text>
          <Text>
            <strong>Is Automated FollowUp: </strong>
            {call.is_automated_follow_up !== undefined &&
            call.is_automated_follow_up !== null
              ? String(call.is_automated_follow_up)
              : "False"}
          </Text>
        </Box>
      ))}

      {/* Pagination Controls */}
      <Flex justifyContent="center" mt={4} gap={4}>
        <Button onClick={handlePreviousPage} isDisabled={page === 1}>
          Previous
        </Button>
        <Text>Page {page}</Text>
        <Button
          onClick={handleNextPage}
          isDisabled={calls.length < itemsPerPage}
        >
          Next
        </Button>
      </Flex>
    </Flex>
  );
}
