import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Flex,
  Divider,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  Input
} from "@chakra-ui/react";
import { Form, FormLayout, SubmitButton } from "@saas-ui/react";
import config from "../../config";

const Settings = () => {

  const [settings, setSettings] = useState({
    callsPerDay: 1,
    daysToCall: 1,
    callFrequency: 30,
  });

    useEffect(() => {
      const getSettings = async () => {
        try {
          const requestOptions = {
            method: "GET",
          };
          const response = await fetch(
            `${config.apiDomain}/critical_settings`,
            requestOptions,
          );
          const jsonResponse = await response.json();
          console.log(jsonResponse);

          const settings = jsonResponse.settings;

          setSettings({
            callsPerDay: settings.calls_per_day,
            daysToCall: settings.days_to_call,
            callFrequency: settings.call_frequency,
            excludeFrom: settings.exclude_from || "",
            excludeTo: settings.exclude_to || "",
          });
        } catch (error) {
          console.error("Error fetching settings", error);
        }
      };

      getSettings();
    }, []);

  const handleChange = (field, value) => {
    setSettings((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = async (event) => {
    try {
      const response = await fetch(`${config.apiDomain}/critical_settings`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          callsPerDay: settings.callsPerDay,
          daysToCall: settings.daysToCall,
          callFrequency: settings.callFrequency,
          excludeFrom: settings.excludeFrom,
          excludeTo: settings.excludeTo,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        console.error("Error:", data.error);
      } else {
        console.log("Settings saved successfully:", data);
      }
    } catch (error) {
      console.error("Error saving settings:", error);
    }
  };


    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bg="gray.50"
        mt={10} // Adds margin to the top of the Flex container
      >
        <Box
          p={6}
          bg="white"
          color="black"
          borderRadius="md"
          shadow="md"
          width={{ base: "100%", sm: "80%", md: "60%", lg: "40%" }} // Responsive width
        >
          <Flex justifyContent="space-between" alignItems="center" mb={4}>
            <Heading size="md">Critical Verification Settings</Heading>
          </Flex>
          <Divider mb={4} />
          <Form onSubmit={handleSubmit}>
            <FormLayout>
              <FormControl>
                <FormLabel>Number of Calls Per Day</FormLabel>
                <NumberInput
                  min={0}
                  value={settings.callsPerDay}
                  onChange={(valueString) =>
                    handleChange("callsPerDay", parseInt(valueString, 10))
                  }
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>
              <FormControl>
                <FormLabel>Number of Days to Call</FormLabel>
                <NumberInput
                  min={0}
                  value={settings.daysToCall}
                  onChange={(valueString) =>
                    handleChange("daysToCall", parseInt(valueString, 10))
                  }
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>
              <FormControl>
                <FormLabel>Call Frequency (Minutes)</FormLabel>
                <NumberInput
                  min={10}
                  step={10}
                  value={settings.callFrequency}
                  onChange={(valueString) =>
                    handleChange("callFrequency", parseInt(valueString, 10))
                  }
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>

              <FormControl>
                <FormLabel>Exclude Calls From</FormLabel>
                <Input
                  type="time"
                  value={settings.excludeFrom}
                  onChange={(e) => handleChange("excludeFrom", e.target.value)}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Exclude Calls To</FormLabel>
                <Input
                  type="time"
                  value={settings.excludeTo}
                  onChange={(e) => handleChange("excludeTo", e.target.value)}
                />
              </FormControl>

              <SubmitButton colorScheme="teal">Save Settings</SubmitButton>
            </FormLayout>
          </Form>
        </Box>
      </Flex>
    );
};

export default Settings;
