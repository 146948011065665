import React, { useState } from "react";
import { Outlet, Link } from "react-router-dom";
import {
  Spacer,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import {
  AppShell,
  Sidebar,
  SidebarToggleButton,
  SidebarSection,
  NavItem,
  PersonaAvatar,
  Persona,
  PersonaDetails,
  PersonaLabel
} from "@saas-ui/react";
import { FiHome, FiSettings } from "react-icons/fi";
import { SlCallOut } from "react-icons/sl";
import { MdHistory } from "react-icons/md";
import "./CustomSidebar.css"; // Import the CSS file
import CallForm from "../callform/CallForm";
import Home from "../home/Home"
import { useNavigate } from "react-router-dom"; // Import useNavigate
import config from "../../config";
import CallHistory from "../callhistory/CallHistory";
import Settings from "../settingsform/Settings";

const CustomSidebar = (props) => {
  const [activeItem, setActiveItem] = useState("Home");
  const navigate = useNavigate(); 

  const logout = async (e) => {
     await fetch(`${config.apiDomain}/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
       body: JSON.stringify({ token: localStorage.getItem("token") }),
     }).then((result) => { navigate("/"); }).catch((e) => { 
       console.log(e);
       navigate("/");
     })
  };

   return (
     <AppShell
       sidebar={
         <Sidebar
           className="custom-sidebar"
           width={["100px", "250px", "300px"]}
           toggleBreakpoint="sm"
         >
           <SidebarToggleButton />

           {/* Top user menu section */}
           <SidebarSection direction="row">
             <Menu>
               <MenuButton
                 as={IconButton}
                 icon={
                   <Persona>
                     <PersonaAvatar
                       presence="online"
                       size="xs"
                       src="/showcase-avatar.jpg"
                       name="Tyler"
                     />
                     <PersonaDetails>
                       <PersonaLabel>Tyler</PersonaLabel>
                     </PersonaDetails>
                   </Persona>
                 }
                 variant="ghost"
               />
               <MenuList>
                 <MenuItem onClick={logout}>Sign out</MenuItem>
               </MenuList>
             </Menu>
             <Spacer />
           </SidebarSection>

           {/* Main navigation section */}
           <SidebarSection aria-label="Main">
             <NavItem as={Link} to="/" icon={<FiHome />} fontSize="lg" py={6}>
               Home
             </NavItem>
             <NavItem
               as={Link}
               to="/call-initiation"
               icon={<SlCallOut />}
               fontSize="lg"
               py={6}
             >
               Call Initiation
             </NavItem>
             <NavItem
               as={Link}
               to="/call-history"
               icon={<MdHistory />}
               fontSize="lg"
               py={6}
             >
               Call History
             </NavItem>
             <NavItem
               as={Link}
               to="/settings"
               icon={<FiSettings />}
               fontSize="lg"
               py={6}
             >
               Settings
             </NavItem>
           </SidebarSection>
         </Sidebar>
       }
     >
       {/* The <Outlet> is where child routes get rendered */}
       <Outlet />
     </AppShell>
   );
};

export default CustomSidebar;
