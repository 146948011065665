import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import CustomSidebar from "./components/sidebar/CustomSidebar";
// import CustomNavbar from "./components/CustomNavbar";
// import LoginForm from "./components/loginform/LoginForm";
import Home from "./components/home/Home"
import CallForm from "./components/callform/CallForm";
import CallDetails from "./components/calldetails/CallDetails";
import CallHistory from "./components/callhistory/CallHistory";
import Settings from "./components/settingsform/Settings";

import 'bootstrap/dist/css/bootstrap.min.css';


const App = () => {
 return (
   <Router>
     <Routes>
       {/* "Layout Route" using the CustomSidebar */}
       <Route path="/" element={<CustomSidebar />}>
         {/* All child routes will show up inside the sidebar layout */}
         <Route index element={<Home />} />
         <Route path="call-initiation" element={<CallForm />} />
         <Route path="call-history" element={<CallHistory />} />
         <Route path="settings" element={<Settings />} />

         {/* For call details, with a parameter */}
         <Route path="calls/:callId" element={<CallDetails />} />

         {/* You can add more routes as needed */}
       </Route>
     </Routes>
   </Router>
 );
};

export default App;
